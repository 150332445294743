body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
}

.sidebar {
  width: 100%;
  background-color: #007bff;
  color: #fff;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.main {
  width: 100%;
}

.filter {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filter input {
  width: 50%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: calc(33.333% - 20px);
  box-sizing: border-box;
  border-left: 5px solid #007bff;
}

.card h3 {
  margin-top: 0;
  color: #007bff;
}

.card p {
  margin: 5px 0;
}

.card a {
  color: #007bff;
  text-decoration: none;
}

.card a:hover {
  text-decoration: underline;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.scroll-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.scroll-button:hover {
  background-color: #0056b3;
}

.footer {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  border-top: 1px solid #e7e7e7;
  width: 100%;
  position: relative;
  bottom: 0;
  margin-top: auto;
}

.footer a {
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}